import React, { useRef, useEffect, useState } from "react";
import "../../css/content/pages/_admin.scss";
import firebase from "../../service/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import deleteicon from "../../assets/deleteicon.svg";
import addicon from "../../assets/addicon.svg";

function Admin() {
  const boardRef = useRef();
  const registryRef = useRef();
  const companyRef = useRef();
  const dbUrl =
    "https://aurelius-29705-default-rtdb.europe-west1.firebasedatabase.app/volgnikud/-NOKM1laZcxbbGcDPP3O.json";
  const [stockData, setStockData] = useState([]);
  const [showData, setShowData] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const auth = getAuth();
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        // ...
        setShowData(true);
      } else {
        // User is signed out
        // ...
        setShowData(false);
      }
    });
  }, []);

  useEffect(() => {
    fetch(dbUrl)
      .then((response) => response.json())
      .then((responseBody) => {
        const dataFromDb = [];
        for (const key in responseBody) {
          dataFromDb.push(responseBody[key]);
        }
        setStockData(dataFromDb);
      });
  }, []);

  const handleAddDebtor = async () => {
    // Reset messages
    setErrorMessage("");
    setSuccessMessage("");

    // Validate inputs
    const companyValue = companyRef.current?.value?.trim();
    const registryValue = registryRef.current?.value?.trim();
    const boardValue = boardRef.current?.value?.trim();

    if (!companyValue || !registryValue || !boardValue) {
      setErrorMessage("Palun täida kõik väljad");
      return;
    }

    // Start submission
    setIsSubmitting(true);

    const debtorData = {
      company: companyValue,
      registryCode: registryValue,
      board: boardValue,
    };

    try {
      // Try up to 3 times if needed
      let attempts = 0;
      let success = false;

      while (attempts < 3 && !success) {
        attempts++;

        try {
          const response = await fetch(dbUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(debtorData),
          });

          if (!response.ok) {
            throw new Error(
              `Server returned ${response.status}: ${response.statusText}`
            );
          }

          const result = await response.json();

          success = true;
          setStockData((prevData) => [...prevData, debtorData]);

          companyRef.current.value = "";
          registryRef.current.value = "";
          boardRef.current.value = "";

          setSuccessMessage("Edukalt lisatud!");

          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        } catch (error) {
          console.error(`Attempt ${attempts} failed:`, error);

          if (attempts >= 3) {
            setErrorMessage(`Viga: ${error.message}. Proovisime 3 korda.`);

            setTimeout(() => {
              setErrorMessage("");
            }, 5000);
          }

          if (attempts < 3) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
          }
        }
      }
    } catch (error) {
      console.error("Error in submission process:", error);
      setErrorMessage("Tekkis viga. Palun proovi uuesti.");

      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } finally {
      setIsSubmitting(false);
    }
  };

  function deleteDebtor(storedDebtor) {
    const index = stockData.findIndex(
      (element) => element.registryCode === storedDebtor.registryCode
    );
    stockData.splice(index, 1);
    setStockData(stockData.slice());

    fetch(dbUrl, {
      method: "PUT",
      body: JSON.stringify(stockData),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  return (
    <div className="relative-container">
      {showData && (
        <div className="form-container">
          <div className="form-content">
            <h1 className="form-heading">Lisa/kustuta</h1>

            {/* Add New Form Section */}
            <div className="add-form-panel">
              <div className="form-group">
                <label htmlFor="company-input">Nimi</label>
                <input
                  id="company-input"
                  className="form-input"
                  ref={companyRef}
                  type="text"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="registry-input">Registrikood</label>
                <input
                  id="registry-input"
                  className="form-input"
                  ref={registryRef}
                  type="number"
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="board-input">Juhatus</label>
                <input
                  id="board-input"
                  className="form-input"
                  ref={boardRef}
                  type="text"
                  required
                />
              </div>

              <button
                className="btn-add"
                onClick={() => handleAddDebtor()}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <span className="loader"></span>
                ) : (
                  <>
                    <img src={addicon} alt="Add" />
                    <span>Lisa</span>
                  </>
                )}
              </button>
            </div>

            {/* Data Table Section */}
            <div className="data-table-container">
              <div className="data-table-header">
                <div className="table-header-cell">Nimi</div>
                <div className="table-header-cell">Registrikood</div>
                <div className="table-header-cell">Juhatus</div>
                <div className="table-header-cell action-cell"></div>
              </div>

              {[...stockData].reverse().map((element, index) => (
                <div className="data-row" key={index}>
                  <div className="data-cell company-cell">
                    {element.company}
                  </div>
                  <div className="data-cell">{element.registryCode}</div>
                  <div className="data-cell">{element.board}</div>
                  <div className="data-cell action-cell">
                    <button
                      className="btn-delete"
                      onClick={() => deleteDebtor(element)}
                      title="Kustuta"
                    >
                      <img src={deleteicon} alt="Delete" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Simple Toast Messages */}
      <div className="toast-container">
        {errorMessage && (
          <div className="toast error-toast">{errorMessage}</div>
        )}

        {successMessage && (
          <div className="toast success-toast">{successMessage}</div>
        )}
      </div>
    </div>
  );
}

export default Admin;
